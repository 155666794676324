import axios from 'axios';
import { toast } from 'react-toastify';
import { allRequestReport } from '../functions/AdminFunctions';

export let url_base = "https://administrador.nuevaeconomia.com.bo/api/";

export let post = (url: string, data: {}, config: {}) => {

    return new Promise((res, rej) => {
        axios.post(url_base + url, data, config).then(r => {
            allRequestReport(r);
            res(r.data);
        }).catch(c => {
            allRequestReport(c);
            rej(c);
        })
    });
}

export let escapeHtml = (text:string) => {

}

//Strings

export const errorDBName = "errors-bank";
export const requestedDBName = "request-bank";

//Types

export type userType = {
    nombre: string,
    email: string,
    area: string,
    cargo: string,
    imagen: string,
    celular: string,
    pass: string,
    rol: string,
    user_code: number
}

export type usersType = userType[];

export type userKeys = "nombre" | "email" | "area" | "cargo" | "imagen" | "celular" | "pass" | "rol" | "user_code";

export type loginReturnType = {
    confirmed: boolean,
    user: userType,
    others: userType[]
}

export type updateReturnType = {
    exceptions: { message: string, res: any },
    result: boolean
}

//Notas type
export type notaDestacadaType = {
    titulo: string,
    descripcion: string,
    fecha: string,
    posicion: string,
    principal_img: string,
    redes_img: string
}
export type notaDiaType = {
    codigo_nota: number,
    titulo: string,
    descripcion: string,
    fecha: string,
    editor_content: string,
    redes_img: string,
    portada_img: string,
    portada_pie: string,
    revisado: number,
    url: string
}

export type notasDiaType = notaDiaType[]

//Carrusel

export type carruselType = {
    nro: number,
    titulo: string,
    image: string,
    url: string
}

//FUNCTIONS 
export let imgToBase64 = (target: any, onObtain: (img: string) => any) => {

    if (target.files![0]) {

        //Open as Base64 img
        let fr = new FileReader();
        fr.addEventListener("load", (e) => {
            onObtain(e.target?.result as string);
        });
        fr.readAsDataURL(target.files![0]);

    }else{
        toast.error("Porfavor, rellena todos los campos");
    }
}

//ICONS
export let EditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>;

export let DeleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" /></svg>;

export let RefreshIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" /></svg>;

export let DoneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>;

export let DestacadaSelectable = <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="903" height="603" viewBox="0 0 903 603">
<rect id="bg" x="1.5" y="1.5" width="900" height="600" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
<rect id="_1" data-name="1" x="15.05" y="17.22" width="429.96" height="277.79" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
<rect id="_2" data-name="2" x="15.05" y="309.31" width="429.96" height="277.79" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
<rect id="_5" data-name="5" x="460.33" y="309.31" width="426.38" height="277.79" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
<rect id="_3" data-name="3" x="460.33" y="17.22" width="206.3" height="277.79" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
<rect id="_4" data-name="4" x="680.41" y="17.22" width="206.3" height="277.79" fill="#fff" stroke="#3DA8EA" stroke-miterlimit="10" stroke-width="3"/>
</svg>;