import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { madePost } from "../common/post";
import { Course, MagazzinePlan, Product, ResponseGeneral } from "../common/types";
import { Catch } from "../common/variables";
import { CardsManager, CardsProductsManager } from "../components/Card";
import Header from "../components/Header"
import MagazzinePlanCard, { MagazzineCards } from "../components/MagazzineCard";
import NativeTitle from "../components/Title";
import { clone } from "../functions/functions";

import "../theme/styles/pages/Pagos.scss";

const Pagos = () => {
    const [cards, setCards] = useState({ values: [] as any[], info: [] as MagazzinePlan[], isLoaded: false })
    const [courses, setCourses] = useState({ values: [] as Course[], isLoaded: false });
    const [products, setProducts] = useState({values: [] as Product[], isLoaded: false});

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (!cards.isLoaded) {
            madePost(
                {
                    key: "get-magazzine-plans",
                },
                "AdminPagos.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let cs: MagazzinePlan[] = [];
                    console.debug(res)
                    res.VALUE.map((plan: MagazzinePlan, index: number) => {
                        let habs: any = plan.habs;
                        let winns: any = plan.winnings;

                        try {
                            habs = JSON.parse(plan.habs as unknown as any);
                        } catch (err) { console.debug("NOT PARSED, ", err) }

                        try {
                            winns = JSON.parse(plan.winnings as unknown as any);
                        } catch (err) { console.debug("NOT PARSED, ", err) }

                        plan["habs"] = habs;
                        plan["winnings"] = winns;

                        cs.push(plan);
                    })

                    setCards({ values: cs, info: cs, isLoaded: true })
                } else {
                    toast.error("No se han podido obtener los planes de revista")
                }
            }).catch(Catch)
        }

        if (!courses.isLoaded) {
            madePost(
                {
                    key: "get-courses",
                },
                "AdminPagos.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let cs: Course[] = [];

                    res.VALUE.map((c: Course, index: number) => {

                        cs.push(c);
                    })
                    setCourses({ values: cs, isLoaded: true });
                } else {
                    toast.error("No se han podido obtener los cursos de revista")
                }
            }).catch(Catch)
        }

        if (!products.isLoaded) {
            madePost(
                {
                    key: "get-products",
                },
                "AdminPagos.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let cs: Product[] = [];

                    res.VALUE.map((c: Product, index: number) => {

                        cs.push(c);
                    })
                    setProducts({ values: cs, isLoaded: true });
                } else {
                    toast.error("No se han podido obtener los productos")
                }
            }).catch(Catch)
        }
    }, [])

    return <div className="pagos-page">
        <Header />
        <div className="pagos-content">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={index == 0 ? "active" : ""}
                        onClick={() => { setIndex(0) }}
                    >
                        Revista
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={index == 1 ? "active" : ""}
                        onClick={() => { setIndex(1) }}
                    >
                        Cursos
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={index == 2 ? "active" : ""}
                        onClick={() => { setIndex(2) }}
                    >
                        Cerco Verde
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={index}>
                <TabPane tabId={0}>

                    <div className="d-flex flex-row align-items-start justify-content-between w-100">
                        <NativeTitle>Planes de revista</NativeTitle>
                        <Button color="blue" disabled={!cards.isLoaded} onClick={() => {
                            let cardss: any[] = clone(cards.info);

                            cardss.push({
                                name: "Nombre de plan",
                                duration: 6,
                                price: 0,
                                winnings: [{ icon: "check-lg", text: "Crear opciones atractivas" }],
                                habs: ["virtual"],
                                description: "Agregar descripcion",
                                plan_code: -1,
                                active: 1,
                                currency: "BOB"
                            })

                            setCards({ values: cardss, info: clone(cardss), isLoaded: cards.isLoaded })
                        }}>Crear nuevo</Button>
                    </div>

                    <MagazzineCards cards={cards.info} />

                </TabPane>
                <TabPane tabId={1}>
                    <div className="d-flex flex-row flex-wrap align-items-start justify-content-between w-100">
                        <NativeTitle>Cursos</NativeTitle>
                        <div>
                            <span>Crear: </span>
                            <Button color="blue" disabled={!courses.isLoaded} onClick={() => {
                                let cardss: any[] = clone(courses.values);

                                cardss.push({
                                    title: "Nombre del curso",
                                    description: "Descripción",
                                    type: "virtual",
                                    course_code: -1,
                                    details: [],
                                    cover_image: "https://www.tibs.org.tw/images/default.jpg",
                                    price: 0,
                                })

                                setCourses({ values: cardss, isLoaded: true });
                            }}>Curso virtual</Button>
                            <Button className="mx-2" color="blue" disabled={!courses.isLoaded} onClick={() => {
                                let cardss: any[] = clone(courses.values);

                                cardss.push({
                                    title: "Nombre del curso",
                                    description: "Descripción",
                                    type: "face-to-face",
                                    location: "Ubicación",
                                    course_code: -1,
                                    details: [],
                                    cover_image: "https://www.tibs.org.tw/images/default.jpg",
                                    price: 0,
                                })

                                setCourses({ values: cardss, isLoaded: true });
                            }}>Curso presencial</Button>
                            <Button color="blue" disabled={!courses.isLoaded} onClick={() => {
                                let cardss: any[] = clone(courses.values);

                                cardss.push({
                                    title: "Nombre del curso",
                                    description: "Descripción",
                                    type: "train",
                                    location: "Ubicación",
                                    course_code: -1,
                                    details: [],
                                    cover_image: "https://www.tibs.org.tw/images/default.jpg",
                                    price: 0,
                                })

                                setCourses({ values: cardss, isLoaded: true });
                            }}>Taller</Button>
                        </div>
                    </div>

                    <CardsManager cards={courses.values} />

                </TabPane>
                <TabPane tabId={2}>
                    <div className="d-flex flex-row flex-wrap align-items-start justify-content-between w-100">
                        <NativeTitle>Cerco Verde</NativeTitle>
                        <div>
                            <Button color="blue" disabled={!products.isLoaded} onClick={() => {
                                let cardss: Product[] = clone(products.values);

                                cardss.push({
                                    product_code: "-1",
                                    name: "Producto ...",
                                    description: "Descripcion de producto ...",
                                    winnings: [
                                        {
                                            text: "Agrega tus caracteristicas",
                                            icon: "lg-check"
                                        }
                                    ],
                                    provider: {
                                        enterprise: "",
                                        comunity: "N/N",
                                        others: "",
                                        location: "",
                                        locationInfo: "",
                                        sanitaryCode: ""
                                    },
                                    category: "f-amz",
                                    images: [],
                                    price: 0
                                })

                                setProducts({ values: cardss, isLoaded: true });
                            }}>Crear producto</Button>
                            
                        </div>
                    </div>

                    <CardsProductsManager cards={products.values} />
                </TabPane>
            </TabContent>

        </div>
    </div>
}

export default Pagos;
